import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { CssBaseline, Grid, makeStyles } from "@material-ui/core";
import { useFirebase } from "../../Firebase/FirebaseContext";
import {
  doc,
  onSnapshot,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import moment from "moment";
import Animation from "../Elements/Animation";
import VideoScreen from "./WaitingScreen/VideoScreen";
import DefaultScreen from "./WaitingScreen/DefaultScreen";
import useVideoCache from "../../hooks/useVideoCache";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    alignContent: "center",
  },
}));

export default function WaitingScreen() {
  const { firebase } = useFirebase();
  const classes = useStyles();
  const location = useLocation();
  const locationData = location.state.data;
  const [technician, setTechnician] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [technicianImg, setTechnicianImg] = useState("");
  const [videoUrls, setVideoUrls] = useState([]);
  const [messageLongWaiting, setMessageLongWaiting] = useState("");
  const [waitingTime, setWaitingTime] = useState(0);
  const [enableWaitingMessage, setEnableWaitingMessage] = useState(false);
  const playerRef = useRef();
  const roomRef = doc(firebase.db, "rooms", locationData.id);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [enableVideo, setEnableVideo] = useState(true);
  const [isWaitingExceeded, setIsWaitingExceeded] = useState(false);
  const [appointmentStartedAt, setAppointmentStartedAt] = useState(null);

  const currentVideoUrl = videoUrls[currentVideoIndex] || null;
  const cachedUrl = useVideoCache(currentVideoUrl);

  const checkWaitingTimeExceeded = (startedAt) => {
    if (!enableWaitingMessage || !waitingTime) return;
    const waitDuration = moment().diff(moment(startedAt.toDate()), "minutes");
    setIsWaitingExceeded(waitDuration > waitingTime);
  };

  useEffect(() => {
    const listener = setTimeout(() => {
      if (!firstName) {
        setIsWaitingExceeded(false);
      }
    }, 10000);
    return () => {
      clearTimeout(listener);
    };
  }, [firstName]);

  useEffect(() => {
    const getCurrentAppointment = () => {
      onSnapshot(roomRef, (roomData) => {
        const appointmentId = roomData.data()?.appointementId;
        if (!appointmentId) {
          resetState();
          return;
        }
        const appointmentRef = doc(firebase.db, "appointements", appointmentId);
        onSnapshot(appointmentRef, async (appointment) => {
          const appointmentData = appointment.data();
          if (!appointmentData) return;
          console.log(appointmentData);
          if (
            appointmentData.roomId &&
            appointmentData.roomId.length > 0 &&
            appointmentData.roomId !== locationData.id
          )
            return;
          const {
            ended_at,
            technician,
            started_at,
            name,
            lastName,
            sex,
            birthday,
            enableVideo,
            technician_email,
          } = appointmentData;

          setEnableVideo(enableVideo !== undefined ? enableVideo : true);
          setAppointmentStartedAt(started_at);

          if (ended_at) {
            resetState();
            return;
          }

          if (technician) {
            const userRef = doc(firebase.db, "users", technician_email);
            const technicianDoc = await getDoc(userRef);
            const technicianData = technicianDoc.data();
            if (technicianData?.profile_picture) {
              const imgUrl = await getDownloadURL(
                ref(getStorage(), technicianData.profile_picture)
              );
              setTechnicianImg(imgUrl);
            }
            setTechnician(technician.split(" ")[0]);
          }

          if (started_at && sex && birthday) {
            const age = moment().diff(moment(birthday, "DD/MM/YYYY"), "years");
            fetchVideos(sex, age);
            await fetchLaboratoryConfig();
          }
          if (started_at) checkWaitingTimeExceeded(started_at);

          setFirstName(name || "");
          setLastName(lastName || "");
        });
      });
    };

    const fetchLaboratoryConfig = async () => {
      const labRef = doc(firebase.db, "laboratories", localStorage.currentLab);
      const labDoc = await getDoc(labRef);
      const labData = labDoc.data();
      if (labData) {
        setMessageLongWaiting(labData.messageLongWaiting || "");
        setWaitingTime(labData.waitingTime || 0);
        setEnableWaitingMessage(labData.enableWaitingMessage || false);
      }
    };

    const resetState = () => {
      setTechnician("");
      setTechnicianImg("");
      setFirstName("");
      setLastName("");
      setVideoUrls([]);
      setIsWaitingExceeded(false);
    };

    const fetchVideos = async (sex, age) => {
      const videoRef = collection(
        firebase.db,
        `laboratories/${localStorage.currentLab}/videos`
      );
      const q = query(videoRef, where("sex", "==", sex));
      const queryResult = await getDocs(q);
      const videoPaths = [];
      queryResult.forEach((queryVideo) => {
        const { path, minAge, maxAge } = queryVideo.data();
        if (minAge <= age && maxAge >= age) {
          videoPaths.push(path);
        }
      });

      const storage = getStorage();
      const urls = await Promise.all(
        videoPaths.map(async (path) => {
          const url = await getDownloadURL(ref(storage, path));
          return url;
        })
      );
      setVideoUrls(urls.reverse());
      setCurrentVideoIndex(0);
    };

    getCurrentAppointment();
  }, [firebase.db, locationData.id]);

  useEffect(() => {
    const checkWaitingInterval = setInterval(() => {
      if (!enableWaitingMessage || !waitingTime || !appointmentStartedAt)
        return;
      const waitDuration = moment().diff(
        moment(appointmentStartedAt.toDate()),
        "minutes"
      );
      setIsWaitingExceeded(waitDuration > waitingTime);
    }, 1000);

    return () => clearInterval(checkWaitingInterval);
  }, [enableWaitingMessage, waitingTime, appointmentStartedAt]);

  const handleVideoEnded = () => {
    if (videoUrls.length === 1) {
      // On remet la vidéo au début
      playerRef.current.seekTo(0, "seconds");
    } else {
      // Sinon, on passe à la suivante (ou on boucle sur la liste)
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
    }
  };
  const isVideoAvailable = videoUrls.length > 0 && enableVideo;

  return (
    <Grid container component="main" className={classes.root}>
      {localStorage.username && localStorage.role && (
        <Redirect push to="/home" />
      )}
      <CssBaseline />
      {isVideoAvailable ? (
        <VideoScreen
          firstName={firstName}
          lastName={lastName}
          technician={technician}
          technicianImg={technicianImg}
          videoUrl={currentVideoUrl}
          handleVideoEnded={handleVideoEnded}
          playerRef={playerRef}
          isWaitingExceeded={isWaitingExceeded}
          messageLongWaiting={messageLongWaiting}
          cachedUrl={cachedUrl}
        />
      ) : (
        <DefaultScreen
          firstName={firstName}
          lastName={lastName}
          technician={technician}
          technicianImg={technicianImg}
          isWaitingExceeded={isWaitingExceeded}
          messageLongWaiting={messageLongWaiting}
        />
      )}
      <Animation />
    </Grid>
  );
}
