import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { CreditCard, Description } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  iconHolder: {
    width: 500,
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: "-150px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  iconHolderVideo: {
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    fontSize: "7rem",
    color: theme.palette.primary.main,
  },
  iconVideo: {
    fontSize: "3rem",
    color: theme.palette.primary.main,
  },
}));

export default function DefaultTechnicianMessage({ isVideo = false }) {
  const classes = useStyles();

  const variant = isVideo ? "body1" : "h3";
  const iconClass = isVideo ? classes.iconVideo : classes.icon;
  const iconHolderClass = isVideo
    ? classes.iconHolderVideo
    : classes.iconHolder;

  return (
    <div style={{ position: "relative", width: isVideo ? 300 : undefined }}>
      <Typography variant={variant} style={{ padding: "50px" }}>
        Installez-vous sur le fauteuil, un préleveur va vous prendre en charge.
      </Typography>
      <Typography
        variant={variant}
        style={{ padding: isVideo ? "0 10px 5px" : "0 50px 25px" }}
      >
        Merci d’insérer votre carte vitale dans le lecteur et de préparer votre
        ordonnance et votre carte de mutuelle.
      </Typography>
      <div className={iconHolderClass}>
        <CreditCard className={iconClass} />
        <Description className={iconClass} />
      </div>
    </div>
  );
}
