import React, { useCallback, useEffect, useState } from "react";
import { useFirebase } from "../../Firebase/FirebaseContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: "center",
    width: "40%",
    margin: 50,
    borderTopLeftRadius: theme.spacing(5),
    borderBottomRightRadius: theme.spacing(5),
    backgroundColor: "rgba(255,255,255,0.5)",
    boxShadow: "0 5px 10px rgba(100, 100, 100, 0.5)",
    transition: "450ms",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.8)",
    },
  },
}));
export default function Home() {
  const { firebase } = useFirebase();
  const history = useHistory();
  const classes = useStyles();
  const [rooms, setRooms] = useState([]);
  const [roomsRetryAttempt, setRoomsRetryAttempt] = useState(0);
  const [labRetryAttempt, setLabRetryAttempt] = useState(0); // Compteur de retry pour le lab

  const fetchRooms = useCallback(async () => {
    const currentLab = localStorage.getItem("currentLab");

    if (!currentLab) {
      console.warn("currentLab non défini dans le localStorage.");
      if (labRetryAttempt < 3) {
        setTimeout(() => {
          setLabRetryAttempt((prev) => prev + 1);
          fetchRooms();
        }, 500);
      } else {
        console.error("currentLab non défini après plusieurs tentatives.");
      }
      return;
    }

    try {
      const q = query(
        collection(firebase.db, "rooms"),
        where("laboratoryId", "==", currentLab)
      );
      const querySnapshot = await getDocs(q);
      const arr = [];

      querySnapshot.forEach((doc) => {
        arr.push({ room: doc.data(), id: doc.id });
      });

      arr.sort((a, b) => a.room.name.localeCompare(b.room.name));
      setRooms(arr);
    } catch (err) {
      console.error("Erreur lors du fetch des rooms :", err);
    }
  }, [firebase, labRetryAttempt]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  useEffect(() => {
    if (rooms.length === 0 && roomsRetryAttempt < 1 && labRetryAttempt === 0) {
      const timeout = setTimeout(() => {
        setRoomsRetryAttempt((prev) => prev + 1);
        fetchRooms();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [rooms, roomsRetryAttempt, labRetryAttempt, fetchRooms]);

  return (
    <Grid container justifyContent="space-around" wrap="wrap">
      {rooms.map((data, i) => (
        <Card
          key={`uniqueKey-${i}`}
          className={classes.card}
          onClick={() => {
            history.push("/waitingScreen", { data: data });
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {data.room.name}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}
