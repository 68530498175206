import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";
import DefaultTechnicianMessage from "./DefaultTechnicianMessage";

const useStyles = makeStyles((theme) => ({
  customerInfoVideo: {
    position: "absolute",
    top: "5%",
    left: "5%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
    maxWidth: 450,
  },
  technicianInfoVideo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "5%",
    right: "3%",
    zIndex: 10,
    backgroundColor: "#fff",
    padding: 10,
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.3)",
  },
  imageVideo: {
    margin: 10,
    width: 150,
    height: 150,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  technicianVideo: {
    textAlign: "center",
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
}));

export default function VideoScreen({
  firstName,
  lastName,
  technician,
  technicianImg,
  videoUrl,
  handleVideoEnded,
  playerRef,
  isWaitingExceeded,
  messageLongWaiting,
  cachedUrl,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.customerInfoVideo}>
        <div>
          <Typography variant="h3" style={{ padding: "50px" }}>
            Bonjour,
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "#2979ff", textTransform: "capitalize" }}
          >
            {firstName}
            <br />
            {lastName}
          </Typography>
        </div>

        {isWaitingExceeded && (
          <Typography
            variant="body1"
            style={{
              color: "#005284",
              marginTop: 10,
              marginLeft: 10,
              fontSize: "2.5rem",
            }}
          >
            {messageLongWaiting}
          </Typography>
        )}
      </div>

      <ReactPlayer
        ref={playerRef}
        url={cachedUrl || videoUrl}
        playing
        onEnded={handleVideoEnded}
        width="70%"
        height="auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
        config={{
          file: {
            attributes: {
              controls: true,
              preload: "auto",
            },
          },
        }}
      />

      <div className={classes.technicianInfoVideo}>
        {technician ? (
          <>
            <Typography variant="h5" style={{ paddingBottom: 10 }}>
              Vous êtes pris en charge par :
            </Typography>
            <div style={{ position: "relative" }}>
              <img
                className={classes.imageVideo}
                src={technicianImg || "images/technician_placholder.jpg"}
                alt="Technician Profile"
              />
              <Typography variant="h5" className={classes.technicianVideo}>
                {technician}
              </Typography>
            </div>
          </>
        ) : (
          <DefaultTechnicianMessage isVideo />
        )}
      </div>
    </>
  );
}
