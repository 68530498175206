import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import DefaultTechnicianMessage from "./DefaultTechnicianMessage";

const useStyles = makeStyles((theme) => ({
  bgColor: {},
  section: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  customer: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  image: {
    margin: 10,
    width: 500,
    height: 500,
    borderRadius: "50%",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
  technician: {
    textAlign: "center",
    position: "absolute",
    bottom: "-20px",
    left: "30%",
    width: "100%",
    backgroundColor: "rgba(41, 121, 255,0.8)",
    color: "#fff",
    boxShadow: "5px 10px 15px rgba(100, 100, 100, 0.6)",
  },
}));

export default function DefaultScreen({
  firstName,
  lastName,
  technician,
  technicianImg,
  isWaitingExceeded,
  messageLongWaiting,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={6} className={classes.bgColor}>
        <div className={classes.section}>
          <Typography variant="h2" style={{ padding: "50px", width: "25vw" }}>
            Bonjour,
          </Typography>
          <Typography variant="h2" className={classes.customer}>
            {firstName}
            <br />
            {lastName}
          </Typography>
          {isWaitingExceeded && (
            <Typography
              variant="body1"
              style={{
                color: "#005284",
                marginTop: 10,
                marginLeft: 10,
                fontSize: "3rem",
              }}
            >
              {messageLongWaiting}
            </Typography>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.section}>
          {technician ? (
            <>
              <Typography variant="h2" style={{ paddingBottom: 25 }}>
                Vous êtes pris en charge par :
              </Typography>
              <div style={{ position: "relative" }}>
                <img
                  className={classes.image}
                  src={technicianImg || "images/technician_placholder.jpg"}
                  alt="Technician Profile"
                />
                <Typography variant="h2" className={classes.technician}>
                  {technician}
                </Typography>
              </div>
            </>
          ) : (
            <DefaultTechnicianMessage />
          )}
        </div>
      </Grid>
    </>
  );
}
