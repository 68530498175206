import { useState, useEffect } from "react";
import db from "../dexie/db";

export default function useVideoCache(videoUrl) {
  const [cachedUrl, setCachedUrl] = useState(null);
  const [currentBlobUrl, setCurrentBlobUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const cacheVideo = async () => {
      if (!videoUrl) return;

      // Vérifie si la vidéo est déjà en cache
      const cachedVideo = await db.videos.get(videoUrl);
      if (cachedVideo && cachedVideo.blob) {
        // On recrée un blobUrl à partir du blob stocké
        const blobUrl = URL.createObjectURL(cachedVideo.blob);
        if (isMounted) {
          setCachedUrl(blobUrl);
          setCurrentBlobUrl(blobUrl);
        }
        return;
      }

      // Sinon, on la télécharge
      const response = await fetch(videoUrl);
      const blob = await response.blob();

      // On stocke le blob directement dans la base
      await db.videos.put({ url: videoUrl, blob });

      const blobUrl = URL.createObjectURL(blob);
      if (isMounted) {
        setCachedUrl(blobUrl);
        setCurrentBlobUrl(blobUrl);
      }
    };

    cacheVideo();

    return () => {
      isMounted = false;
      // On libère l'URL si elle existe
      if (currentBlobUrl) {
        URL.revokeObjectURL(currentBlobUrl);
      }
    };
  }, [videoUrl]);

  return cachedUrl;
}
