import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  getDocs,
} from "firebase/firestore";

const CreateLaboratoryWithRooms = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [labName, setLabName] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState(0);
  const [structure, setStructure] = useState("");
  const [structures, setStructures] = useState([]);

  const db = getFirestore();

  // Récupérer les structures existantes
  useEffect(() => {
    const fetchStructures = async () => {
      try {
        const labsQuery = query(collection(db, "laboratories"));
        const labsSnapshot = await getDocs(labsQuery);
        const allStructures = labsSnapshot.docs.map(
          (doc) => doc.data().structure
        );
        setStructures([...new Set(allStructures)]); // Supprimer les doublons
      } catch (error) {
        console.error("Erreur lors de la récupération des structures :", error);
      }
    };

    fetchStructures();
  }, [db]);

  const createLaboratory = async () => {
    if (!labName || numberOfRooms <= 0 || !structure) {
      setMessage("Veuillez remplir tous les champs correctement.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      // Créer le laboratoire
      const labRef = await addDoc(collection(db, "laboratories"), {
        name: labName,
        structure,
      });

      const labId = labRef.id;

      // Ajouter les salles à la collection "rooms"
      for (let i = 1; i <= numberOfRooms; i++) {
        await addDoc(collection(db, "rooms"), {
          name: `Salle ${i}`,
          laboratoryId: labId,
          appointmentId: "",
          locked: false,
          start_locker: null, // Ou remplacez par une valeur de date si nécessaire
        });
      }

      setMessage("Laboratoire et salles créés avec succès !");
    } catch (error) {
      console.error("Erreur lors de la création :", error);
      setMessage("Une erreur est survenue lors de la création.");
    }

    setLoading(false);
  };

  return (
    <div>
      <h1>Créer un laboratoire</h1>

      <div>
        <label>
          Nom du laboratoire :
          <input
            type="text"
            value={labName}
            onChange={(e) => setLabName(e.target.value)}
            disabled={loading}
          />
        </label>
      </div>

      <div>
        <label>
          Nombre de salles :
          <input
            type="number"
            value={numberOfRooms}
            onChange={(e) => setNumberOfRooms(Number(e.target.value))}
            disabled={loading}
            min="1"
          />
        </label>
      </div>

      <div>
        <label>
          Structure :
          <select
            value={structure}
            onChange={(e) => setStructure(e.target.value)}
            disabled={loading}
          >
            <option value="">-- Sélectionnez une structure --</option>
            {structures.map((struct, index) => (
              <option key={index} value={struct}>
                {struct}
              </option>
            ))}
          </select>
        </label>
      </div>

      <button onClick={createLaboratory} disabled={loading}>
        {loading ? "Création en cours..." : "Créer le laboratoire"}
      </button>

      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateLaboratoryWithRooms;
